import { Typography, Grid, Alert, Snackbar } from "@mui/material";
import Modal from "../../../../components/Modal/Modal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteCompanyPickUpRequestVoucherType } from "../../../../hooks/useCompanyPickUpRequestVoucherTypes";
import { useState } from "react";
import { removeCompanyUser } from "../../../../hooks/useCompanyUsers";

const CompanyAccountUserDeleteModal = ({
  accessToken,
  companyId,
  open,
  setOpen,
  selectedUser,
  setSelectedUser,
}: {
  accessToken: string;
  companyId: string;
  selectedUser: any;
  setSelectedUser: any;
  open: boolean;
  setOpen: any;
}) => {
  const queryClient = useQueryClient();
  const [showSuccess, setShowSuccess] = useState(false);
  const [submitError, setSubmitError] = useState("");

  const {
    mutate: handleRemoveCompanyUser,
    isLoading: isRemoveCompanyUserLoading,
  } = useMutation({
    mutationFn: removeCompanyUser,
    onSuccess: async (data) => {
      // resetFields();
      await queryClient.cancelQueries({
        queryKey: ["company-users", companyId],
      });

      await queryClient.invalidateQueries({
        queryKey: ["company-users", companyId],
      });
      setOpen(false);
      setSelectedUser(null);
      setShowSuccess(true);
    },
    onError: async (error: any) => {
      console.log("error adding new payment method", error);
      setSubmitError(error);
    },
  });

  const handleSubmit = () => {
    handleRemoveCompanyUser({
      accessToken,
      companyId,
      userId: selectedUser?.id,
    });
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedUser(null);
  };
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(submitError)}
        autoHideDuration={6000}
        onClose={(reason) => {
          console.log("reason", reason);
          setSubmitError("");
        }}
      >
        <Alert
          onClose={() => {
            setSubmitError("");
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          {submitError}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showSuccess}
        autoHideDuration={6000}
        onClose={() => {
          setShowSuccess(false);
        }}
      >
        <Alert
          onClose={() => {
            setShowSuccess(false);
          }}
          severity="success"
          sx={{ width: "100%" }}
        >
          Company User Removed
        </Alert>
      </Snackbar>
      <Modal
        open={open}
        loading={isRemoveCompanyUserLoading}
        onSubmit={handleSubmit}
        onCancel={handleClose}
        handleClose={handleClose}
        title={"Confirm user removal"}
        submitButtonLabel="Remove"
      >
        <Grid item>
          <Typography>Are you sure you want to remove this user?</Typography>
        </Grid>
      </Modal>
    </>
  );
};

export default CompanyAccountUserDeleteModal;

import { Badge, Box, Breadcrumbs, Grid, Typography } from "@mui/material";
import DashboardLayout from "../../layout/DashboardLayout/DashboardLayout";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import BreadcrumbSection from "../../components/BreadcrumbSection/BreadcrumbSection";
import { getDriversCSV, useDrivers } from "../../hooks/useDrivers";
import { getAccessToken } from "../../helpers/authHelpers";
import { useState } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridFilterModel,
  GridLogicOperator,
  GridPaginationModel,
  GridSortModel,
} from "@mui/x-data-grid";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { darken, lighten, styled } from "@mui/material/styles";
import PaginationTable from "../../components/PaginationTable/PaginationTable";
import moment from "moment";
import {
  mappedVehicleTypes,
  toTitleCase,
} from "../../helpers/validationHelpers";
import useCSVDownloadState from "../../hooks/useCSVDownloadState";
import { AuthContext } from "../../context/authContext";
import { userAuthState } from "../../atom";
import { useRecoilState } from "recoil";

const Drivers = () => {
  const {
    csvDownloadLoading: driversCsvDownloadLoading,
    setCsvDownloadLoading: setDriversCsvDownloadLoading,
    csvData: driversCsvData,
    setCsvData: setDriversCsvData,
    setCsvFilename: setDriversCsvFilename,
    csvLinkRef: driversCsvLinkRef,
    setShouldDownload: setShouldDownloadDriversCsv,
  } = useCSVDownloadState();

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    quickFilterValues: [],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
  });

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "createdAt",
      sort: "desc",
    },
  ]);

  const location = useLocation();
  const navigate = useNavigate();

  const [authDetails, setAuthDetails] = useRecoilState(userAuthState);

  const {
    data: driversData,
    isLoading,
    isFetching,
  } = useDrivers({
    accessToken: authDetails?.accessToken,
    page: paginationModel.page,
    search: filterModel?.quickFilterValues?.join(" "),
    sort: sortModel,
  });

  const handlePaginationModelChange = async (model: GridPaginationModel) => {
    setPaginationModel(model);
  };

  const handleFilterModelChange = async (model: GridFilterModel) => {
    setFilterModel(model);
  };

  const handleSortModelChange = async (model: GridSortModel) => {
    setSortModel(model);
  };

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .Mui-odd": {
      backgroundColor: "#EFF3F5",
      // "&:hover": {
      //   backgroundColor: "#EFF3F5",
      // },
      // "&.Mui-selected": {
      //   backgroundColor: "#EFF3F5",
      //   "&:hover": {
      //     backgroundColor: "#EFF3F5",
      //   },
      // },
    },
  }));

  const columns: GridColDef[] = [
    {
      field: "isOnline",
      headerName: "Active",
      width: 90,
      renderCell: (params) =>
        params.row.isOnline === true ? (
          <Grid container spacing={1}>
            <Grid item>
              <Typography sx={{ color: "#87C43B" }}>●</Typography>
            </Grid>
            <Grid item>
              <Typography>Active</Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={1}>
            <Grid item>
              <Typography sx={{ color: "#FD5554" }}>●</Typography>
            </Grid>
            <Grid item>
              <Typography>Inactive</Typography>
            </Grid>
          </Grid>
        ),
    },
    {
      field: "id",
      headerName: "Driver ID",
      width: 125,
      renderCell: (params) => (
        <Link to={`/dashboard/drivers/${params?.row?.id}`}>
          {params?.row?.id}
        </Link>
      ),
    },
    {
      field: "firstName",
      headerName: "First name",
      width: 125,
      editable: true,
    },
    {
      field: "lastName",
      headerName: "Last name",
      width: 125,
      editable: true,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 100,
      renderCell: (params) => (
        <Typography>
          {moment(params.row.createdAt).format("DD/MM/YYYY")}
        </Typography>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      // type: "number",
      width: 150,
      editable: true,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      width: 125,
    },
    {
      field: "driverType",
      headerName: "Driver type",
      width: 175,
      valueGetter: (params) =>
        `${toTitleCase(params?.row?.driverRegistrationDetail?.driverType)} ${
          params?.row?.fleetOwnerships
            ? "- Fleet"
            : params?.row?.fleetMemberships?.length > 0
            ? "- Fleet Member"
            : ""
        }`,
    },
    {
      field: "vehicleType",
      headerName: "Vehicle",
      valueGetter: (params) =>
        mappedVehicleTypes[
          params?.row?.driverRegistrationDetail?.driverVehicleInformation
            ?.vehicleType
        ],
    },
    {
      field: "preferredCity",
      headerName: "Preferred City",
      valueGetter: (params) =>
        params?.row?.driverRegistrationDetail?.preferredCity,
    },
    {
      field: "driverEvaluationSubmitted",
      headerName: "Typeform Submission",
      width: 150,
      valueGetter: (params) =>
        params?.row?.driverRegistrationDetail?.driverEvaluationSubmitted
          ? "Submitted"
          : "Pending",
    },
    {
      field: "bankingDetailsSubmitted",
      headerName: "Banking Details",
      valueGetter: (params) =>
        params?.row?.stripeConnectProfile &&
        params?.row?.stripeConnectProfile?.stripeConnectPaymentMethods.length >
          0
          ? "Submitted"
          : "Pending",
    },
    {
      field: "registrationCompleted",
      headerName: "Registration Status",
      valueGetter: (params) =>
        params?.row?.registrationCompleted ? "Completed" : "Pending",
    },
    {
      field: "driverEvaluationCompleted",
      headerName: "Approval Status",
      valueGetter: (params) =>
        params?.row?.driverRegistrationDetail?.driverEvaluationCompleted
          ? "Approved"
          : "Pending",
    },
    {
      field: "availability",
      headerName: "Availability",
      sortable: false,
      width: 100,
      valueGetter: (params) => params.row.isOnline,
      renderCell: (params) =>
        params.row.isOnline ? (
          <Box
            sx={{
              padding: "6px 15px",
              display: "inline-flex",
              alignItems: "center",
              fontSize: "12px",
              borderRadius: "3px",
              backgroundColor: "#edfff2",
              color: "#3fc05e",
              textTransform: "capitalize",
            }}
          >
            <Typography>On Call</Typography>
          </Box>
        ) : (
          <Box
            sx={{
              padding: "6px 15px",
              display: "inline-flex",
              alignItems: "center",
              fontSize: "12px",
              borderRadius: "3px",
              backgroundColor: "#ddeaff",
              color: "#0062ff",
              textTransform: "capitalize",
            }}
          >
            <Typography>Offline</Typography>
          </Box>
        ),
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={
            params.row.userChangeDrafts.some(
              (draft: any) => !draft.approved
            ) ? (
              <Badge badgeContent={"!"} color="error">
                <VisibilityOutlinedIcon />
              </Badge>
            ) : (
              <VisibilityOutlinedIcon />
            )
          }
          onClick={() => {
            navigate(`/dashboard/drivers/${params.row.id}`);
          }}
          label="View"
        />,
      ],
    },
  ];

  const downloadDriversCsv = async () => {
    setDriversCsvDownloadLoading(true);
    try {
      const result = await getDriversCSV(authDetails?.accessToken);
      setDriversCsvData(result?.data);
      setDriversCsvFilename(result?.filename);
      setShouldDownloadDriversCsv(true);
    } catch (error) {
      console.log("error", error);
    } finally {
      setDriversCsvDownloadLoading(false);
    }
  };

  console.log("driversData", driversData);
  return (
    <DashboardLayout loading={isLoading}>
      <BreadcrumbSection
        crumbs={[
          {
            url: "/dashboard",
            title: "Dashboard",
          },
          {
            url: "/dashboard/drivers",
            title: "Drivers",
          },
        ]}
        pageTitle="Drivers"
      />
      {/* <StyledDataGrid
        sx={{ backgroundColor: "white", mt: 2 }}
        columns={columns}
        loading={isLoading}
        rows={driversData?.pages[paginationModel.page]?.data ?? []}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd"
        }
        // @ts-ignore
        rowModel="server"
        pageSizeOptions={[10]}
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationModelChange}
        paginationMode="server"
        rowCount={driversData?.pages[paginationModel.page]?.totalCount}
        on
      /> */}
      <Grid container pb={4}>
        <PaginationTable
          withSearchBar
          columns={columns}
          loading={isLoading || isFetching}
          rows={driversData?.data ?? []}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={(model: any) => {
            handlePaginationModelChange(model);
          }}
          filterMode="server"
          filterModel={filterModel}
          onFilterModelChange={(model: any) => {
            handleFilterModelChange(model);
          }}
          exportButtonLoading={driversCsvDownloadLoading}
          onExportButtonClick={downloadDriversCsv}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={(model: any) => {
            handleSortModelChange(model);
          }}
          totalCount={driversData?.totalCount}
          pageCount={driversData?.totalPages}
        />
      </Grid>
      {driversCsvData && (
        <a
          style={{ display: "none" }}
          ref={driversCsvLinkRef}
          href={`data:text/csv;charset=utf-8,${escape(driversCsvData)}`}
          download="filename.csv"
        >
          download
        </a>
      )}
    </DashboardLayout>
  );
};

export default Drivers;

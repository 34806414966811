import { useLocation, Navigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userAuthState } from "../../atom";

const RequireAuth = ({ admin = false, children }: any) => {
  const location = useLocation();

  const [authDetails, setAuthDetails] = useRecoilState(userAuthState);

  if (!authDetails?.accessToken) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default RequireAuth;

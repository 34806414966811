import { useRecoilState } from "recoil";
import { userAuthGatewayLoadingState, userAuthState } from "../atom";
import { useEffect, useState } from "react";
import { useAuth } from "./useAuth";

const useAuthGateway = () => {
  const {
    getToken,
    isExpired,
    logout,
    logoutAndResetAuthState,
    refreshToken,
    setToken,
    validateToken,
    resetAuthState,
  } = useAuth();

  const [authDetails, setAuthDetails] = useRecoilState(userAuthState);
  const [authGatewayLoading, setAuthGatewayLoading] = useRecoilState(
    userAuthGatewayLoadingState
  );

  useEffect(() => {
    const login = async () => {
      setAuthGatewayLoading(true);
      try {
        const data = await validateToken();
        setAuthDetails((prev) => ({ ...prev, ...data }));
        console.log("data.role", data.role);
        // do something
      } catch (error) {
        logoutAndResetAuthState();
      } finally {
        setAuthGatewayLoading(false);
      }
    };

    const attemptRefreshAndLogin = async () => {
      setAuthGatewayLoading(true);
      try {
        console.log("attempting refresh...");
        const data = await refreshToken();
        const tokenData = await setToken(data, true);
        setAuthDetails((prev) => ({ ...prev, ...tokenData }));
        login();
      } catch (error) {
        logoutAndResetAuthState();
      } finally {
        setAuthGatewayLoading(false);
      }
    };

    const handleAuth = async () => {
      const token = await getToken();
      if (!token) {
        logoutAndResetAuthState();
      }

      if (!isExpired(token?.expiresAt)) {
        login();
      } else {
        await attemptRefreshAndLogin();
      }
    };

    handleAuth();
  }, []);

  useEffect(() => {
    let refreshTimer: any;
    if (authDetails.accessToken && authDetails?.expiresAt) {
      // set the token to refresh every X milliseconds
      // API uses 1 hour by default
      const remainingTime =
        Number(authDetails?.expiresAt) * 1000 - new Date().getTime();
      refreshTimer = setInterval(async () => {
        // if (authDetails.rememberMe) {
        try {
          const data = await refreshToken();
          const tokenData = await setToken(data, true);
          setAuthDetails((prev) => ({ ...prev, ...tokenData }));
        } catch (error) {
          console.log("error refreshing token", error);
          // await logout();
          // resetAuthState();
          await logoutAndResetAuthState();
        }
      }, remainingTime);
    } else {
      // clear the interval if the token gets removed
      clearInterval(refreshTimer);
    }

    return () => {
      clearInterval(refreshTimer);
    };
  }, [authDetails]);
};

export default useAuthGateway;

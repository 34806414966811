import { useState } from "react";
import { useCompanyBillingSettings } from "../../../../hooks/useCompanyBillingSettings";
import { AuthContext } from "../../../../context/authContext";
import { useCompanyPaymentMethods } from "../../../../hooks/useCompanyPaymentMethods";
import { Grid, IconButton, Typography } from "@mui/material";
import Button from "../../../../components/Button/Button";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { EditOutlined } from "@mui/icons-material";
import PaymentMethodsModal from "./PaymentMethodsModal/PaymentMethodsModal";
import { useRecoilState } from "recoil";
import { userAuthState } from "../../../../atom";

const CompanyBillingSettingsTab = ({ companyId }: { companyId: any }) => {
  const [authDetails, setAuthDetails] = useRecoilState(userAuthState);

  const { data: billingSettingsData, isLoading: isBillingSettingsLoading } =
    useCompanyBillingSettings(authDetails?.accessToken, companyId);

  const {
    data: paymentMethodsData,
    isLoading: isCompanyPaymentMethodsLoading,
  } = useCompanyPaymentMethods(authDetails?.accessToken, companyId);

  console.log("paymentMethodsData", paymentMethodsData);
  const [open, setOpen] = useState(false);

  const editMode =
    paymentMethodsData && billingSettingsData?.companyBillingDetails;

  return (
    <Grid
      sx={{ padding: 2, position: "relative" }}
      rowSpacing={2}
      container
      direction={"column"}
      justifyContent={"space-evenly"}
    >
      <IconButton
        sx={{ position: "absolute", top: "24px", right: "16px" }}
        color="primary"
        onClick={() => {
          setOpen(true);
        }}
      >
        <EditOutlined sx={{ color: "#28a1fa" }} />
      </IconButton>
      <PaymentMethodsModal
        accessToken={authDetails?.accessToken}
        companyId={companyId}
        editMode={editMode}
        companyPaymentMethodData={paymentMethodsData}
        companyBillingDetailsData={billingSettingsData?.companyBillingDetails}
        open={open}
        setOpen={setOpen}
      />
      <Grid
        container
        item
        justifyContent={"space-between"}
        alignContent={"center"}
        alignItems={"center"}
      >
        <Grid container item spacing={1} xs={6}>
          <Grid item xs={12}>
            <Typography fontSize={18} fontWeight={500}>
              Payment Method
            </Typography>
          </Grid>
          {paymentMethodsData ? (
            <Grid item xs={12}>
              <Typography fontSize={14} fontWeight={400}>
                Visa Credit Card ending in ***{paymentMethodsData?.lastFour},
                expiring on {paymentMethodsData?.expiryMonth}/
                {paymentMethodsData?.expiryYear}
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Typography fontSize={14} fontWeight={400}>
                No payment method present for company.
              </Typography>
            </Grid>
          )}
          {billingSettingsData?.companyBillingDetails && (
            <Grid item xs={12}>
              <Typography fontSize={14} fontWeight={400}>
                Billing Address -{" "}
                {billingSettingsData?.companyBillingDetails?.street},{" "}
                {billingSettingsData?.companyBillingDetails?.city},{" "}
                {billingSettingsData?.companyBillingDetails?.province},{" "}
                {billingSettingsData?.companyBillingDetails?.postalCode
                  ? billingSettingsData?.companyBillingDetails?.postalCode?.toUpperCase()
                  : ""}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CompanyBillingSettingsTab;

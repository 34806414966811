import { Box, Breadcrumbs, Grid, Typography } from "@mui/material";
import DashboardLayout from "../../layout/DashboardLayout/DashboardLayout";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import BreadcrumbSection from "../../components/BreadcrumbSection/BreadcrumbSection";
import { useDrivers } from "../../hooks/useDrivers";
import { getAccessToken } from "../../helpers/authHelpers";
import { useState } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridFilterModel,
  GridLogicOperator,
  GridPaginationModel,
  GridSortModel,
} from "@mui/x-data-grid";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { darken, lighten, styled } from "@mui/material/styles";
import PaginationTable from "../../components/PaginationTable/PaginationTable";
import {
  PickUpStatus,
  cancelledStatuses,
  formatVehicleTypesForServiceCall,
  getVehicleTypesForServiceCall,
  mappedVehicleTypes,
  pendingStatuses,
} from "../../helpers/validationHelpers";
import { roundToPrecision } from "../../helpers/formatters";
import moment from "moment";
import useCSVDownloadState from "../../hooks/useCSVDownloadState";
import { AuthContext } from "../../context/authContext";
import { getCompaniesCSV, useCompanies } from "../../hooks/useCompanies";
import Button from "../../components/Button/Button";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import CompanyCreateModal from "./CompanyCreateModal";
import { useRecoilState } from "recoil";
import { userAuthState } from "../../atom";

const Companies = () => {
  const {
    csvDownloadLoading: companiesCsvDownloadLoading,
    setCsvDownloadLoading: setCompaniesCsvDownloadLoading,
    csvData: companiesCsvData,
    setCsvData: setCompaniesCsvData,
    setCsvFilename: setCompaniesCsvFilename,
    csvLinkRef: companiesCsvLinkRef,
    setShouldDownload: setShouldDownloadCompaniesCsv,
  } = useCSVDownloadState();

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    quickFilterValues: [],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "createdAt",
      sort: "desc",
    },
  ]);

  const [showCompanyCreateModal, setShowCompanyCreateModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  // const accessToken = getAccessToken(user);
  const [authDetails, setAuthDetails] = useRecoilState(userAuthState);

  const {
    data: companiesData,
    isLoading,
    isFetching,
  } = useCompanies({
    accessToken: authDetails.accessToken,
    page: paginationModel.page,
    search: filterModel?.quickFilterValues?.join(" "),
    sort: sortModel,
  });

  const handlePaginationModelChange = async (model: GridPaginationModel) => {
    setPaginationModel(model);
  };

  const handleFilterModelChange = async (model: GridFilterModel) => {
    setFilterModel(model);
  };

  const handleSortModelChange = async (model: GridSortModel) => {
    setSortModel(model);
  };

  const renderStatus = (pickUpStatus: PickUpStatus) => {
    if (cancelledStatuses.includes(pickUpStatus)) {
      return (
        <Grid container spacing={1}>
          <Grid item>
            <Typography sx={{ color: "#FD5554" }}>●</Typography>
          </Grid>
          <Grid item>
            <Typography>
              {pickUpStatus === PickUpStatus.scheduledDeclined
                ? "Declined"
                : "Cancelled"}
            </Typography>
          </Grid>
        </Grid>
      );
    } else if (pendingStatuses.includes(pickUpStatus)) {
      return (
        <Grid container spacing={1}>
          <Grid item>
            <Typography sx={{ color: "#1162FB" }}>●</Typography>
          </Grid>
          <Grid item>
            <Typography>Pending</Typography>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid container spacing={1}>
          <Grid item>
            <Typography sx={{ color: "#87C43B" }}>●</Typography>
          </Grid>
          <Grid item>
            <Typography>Completed</Typography>
          </Grid>
        </Grid>
      );
    }
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Company ID",
      width: 250,
      renderCell: (params) => (
        <Link to={`/dashboard/companies/${params.row.id}`}>
          {params.row.id}
        </Link>
      ),
    },
    {
      field: "name",
      headerName: "Company Name",
      width: 125,
      valueGetter: (params) => `${params?.row?.name}`,
      renderCell: (params) =>
        params?.row?.name ? (
          <Typography>{params?.row?.name}</Typography>
        ) : (
          <Typography>N/A</Typography>
        ),
    },
    {
      field: "description",
      headerName: "Description",
      width: 250,
      valueGetter: (params) => `${params?.row?.companyDetails?.description}`,
      renderCell: (params) =>
        params?.row?.companyDetails?.description ? (
          <Typography>{params?.row?.companyDetails?.description}</Typography>
        ) : (
          <Typography>N/A</Typography>
        ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 225,
      renderCell: (params) => (
        <Typography>
          {moment(params.row.createdAt).format("Do MMMM  YYYY, h:mm a")}
        </Typography>
      ),
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      width: 225,
      renderCell: (params) => (
        <Typography>
          {moment(params.row.createdAt).format("Do MMMM  YYYY, h:mm a")}
        </Typography>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<VisibilityOutlinedIcon />}
          onClick={() => {
            navigate(`/dashboard/companies/${params.row.id}`);
          }}
          label="View"
        />,
      ],
    },
  ];

  const downloadCompaniesCSV = async () => {
    setCompaniesCsvDownloadLoading(true);
    try {
      const result = await getCompaniesCSV(authDetails.accessToken);
      setCompaniesCsvData(result?.data);
      setCompaniesCsvFilename(result?.filename);
      setShouldDownloadCompaniesCsv(true);
    } catch (error) {
      console.log("error", error);
    } finally {
      setCompaniesCsvDownloadLoading(false);
    }
  };
  return (
    <DashboardLayout loading={isLoading}>
      <BreadcrumbSection
        crumbs={[
          {
            url: "/dashboard",
            title: "Dashboard",
          },
          {
            url: "/dashboard/companies",
            title: "Business Partnership Platform",
          },
        ]}
        pageTitle="Business Partnership Platform"
      />
      <CompanyCreateModal
        accessToken={authDetails.accessToken}
        open={showCompanyCreateModal}
        setOpen={setShowCompanyCreateModal}
      />
      <Grid container item justifyContent={"flex-end"}>
        <Grid item>
          <Button
            onClick={() => {
              setShowCompanyCreateModal(true);
            }}
            icon={<AddBusinessIcon sx={{ marginRight: 2 }} />}
          >
            Create New Company
          </Button>
        </Grid>
      </Grid>
      <Grid container pb={4}>
        <PaginationTable
          withSearchBar
          columns={columns}
          loading={isLoading || isFetching}
          rows={companiesData?.data ?? []}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={(model: any) => {
            handlePaginationModelChange(model);
          }}
          exportButtonLoading={companiesCsvDownloadLoading}
          onExportButtonClick={downloadCompaniesCSV}
          filterMode="server"
          filterModel={filterModel}
          onFilterModelChange={(model: any) => {
            handleFilterModelChange(model);
          }}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={(model: any) => {
            handleSortModelChange(model);
          }}
          totalCount={companiesData?.totalCount}
          pageCount={companiesData?.totalPages}
        />
      </Grid>
      {companiesCsvData && (
        <a
          style={{ display: "none" }}
          ref={companiesCsvLinkRef}
          href={`data:text/csv;charset=utf-8,${escape(companiesCsvData)}`}
          download="filename.csv"
        >
          download
        </a>
      )}
    </DashboardLayout>
  );
};

export default Companies;

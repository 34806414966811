import {
  Alert,
  Divider,
  FormControl,
  Grid,
  ImageList,
  ImageListItem,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import Modal from "../../../components/Modal/Modal";
import { useEffect, useState } from "react";
import {
  DriverVehicleInformationVehicleTypes,
  isAnyKeyValueTrue,
  validatePresence,
} from "../../../helpers/validationHelpers";
import { range } from "../../../helpers/formatters";
import useFormFieldsBlurState from "../../../hooks/useFormFieldsBlurState";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateDriverVehicleInformation } from "../../../hooks/useDrivers";

const DriverVehicleEditModal = ({
  userId,
  accessToken,
  open,
  setOpen,
  driverVehicleType,
  driverLicensePlateNumber,
  driverMakeOfCar,
  driverCarColor,
  driverCarYear,
  imageListSection,
}: any) => {
  const queryClient = useQueryClient();
  const currentYear = new Date().getFullYear();
  const [showSuccess, setShowSuccess] = useState(false);
  const [vehicleType, setVehicleType] = useState(
    driverVehicleType || DriverVehicleInformationVehicleTypes.pickUpTruck
  );
  const [licensePlateNumber, setLicensePlateNumber] = useState(
    driverLicensePlateNumber || ""
  );
  const [makeOfCar, setMakeOfCar] = useState(driverMakeOfCar || "");
  const [carColor, setCarColor] = useState(driverCarColor || "");
  const [carYear, setCarYear] = useState(driverCarYear || currentYear);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (open) {
      setVehicleType(driverVehicleType);
      setLicensePlateNumber(driverLicensePlateNumber);
      setMakeOfCar(driverMakeOfCar);
      setCarColor(driverCarColor);
      setCarYear(driverCarYear);
    }
  }, [open]);

  const yearRange = range(1960, currentYear).reverse();

  const { blurredFormFields, setFormFieldToBlurred, setFormFieldToFocused } =
    useFormFieldsBlurState({
      vehicleType,
      licensePlateNumber,
      makeOfCar,
      carColor,
      carYear,
    });

  const formErrors = {
    licensePlateNumber: {
      error:
        blurredFormFields.licensePlateNumber &&
        !validatePresence(licensePlateNumber) &&
        "Please enter your license plate number",
    },
    makeOfCar: {
      error:
        blurredFormFields.makeOfCar &&
        !validatePresence(makeOfCar) &&
        "Please enter the make of your car",
    },
    carColor: {
      error:
        blurredFormFields.color &&
        !validatePresence(carColor) &&
        "Please enter the car's color",
    },
  };

  const submitButtonDisabled = [
    formErrors.licensePlateNumber,
    formErrors.makeOfCar,
    formErrors.carColor,
  ].some((value) => isAnyKeyValueTrue(value));

  const {
    mutate: handleDriverVehicleInformationUpdate,
    isLoading: isDriverVehicleInformationUpdateLoading,
  } = useMutation({
    mutationFn: updateDriverVehicleInformation,
    onSuccess: async (data) => {
      console.log("data after submit", data);
      await queryClient.cancelQueries({
        queryKey: ["driver", userId],
      });
      queryClient.setQueryData(["driver", userId], (prev: any) => {
        return {
          ...prev,
          ...data,
        };
      });
      setShowSuccess(true);
    },
    onError: async (error: any) => {
      console.log("error", error);
      setShowError(true);
      setErrorMessage(error);
    },
  });

  const handleSubmit = () => {
    setShowError(false);
    setErrorMessage("");
    handleDriverVehicleInformationUpdate({
      accessToken,
      userId,
      driverFields: {
        vehicleType,
        licensePlateNumber,
        makeOfCar,
        carColor,
        carYear,
      },
    });
  };

  return (
    <Modal
      open={open}
      loading={isDriverVehicleInformationUpdateLoading}
      onSubmit={handleSubmit}
      onCancel={() => {
        setOpen(false);
      }}
      handleClose={() => {
        if (!isDriverVehicleInformationUpdateLoading) {
          setOpen(false);
        }
      }}
      submitButtonLabel="Update"
      title="Vehicle Details"
      submitButtonDisabled={submitButtonDisabled}
    >
      <Grid container spacing={2}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showSuccess}
          autoHideDuration={6000}
          onClose={() => {
            setShowSuccess(false);
          }}
        >
          <Alert
            onClose={() => {
              setShowSuccess(false);
            }}
            severity="success"
            sx={{ width: "100%" }}
          >
            Driver vehicle information updated
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showError}
          autoHideDuration={6000}
          onClose={() => {
            setShowSuccess(false);
            setErrorMessage("");
          }}
        >
          <Alert
            onClose={() => {
              setShowError(false);
              setErrorMessage("");
            }}
            severity="error"
            sx={{ width: "100%" }}
          >
            {errorMessage}
          </Alert>
        </Snackbar>
        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Vehicle Type
              </InputLabel>
              <Select
                value={vehicleType}
                label="Vehicle Type"
                onChange={(e) => {
                  setVehicleType(e.target.value);
                }}
                onFocus={() => {
                  setFormFieldToFocused("vehicleType");
                }}
                onBlur={() => {
                  setFormFieldToBlurred("vehicleType");
                }}
              >
                <MenuItem
                  value={DriverVehicleInformationVehicleTypes.pickUpTruck}
                >
                  Pick Up Truck
                </MenuItem>
                <MenuItem value={DriverVehicleInformationVehicleTypes.cargoVan}>
                  Cargo Van
                </MenuItem>
                <MenuItem
                  value={DriverVehicleInformationVehicleTypes.minivan}
                >
                  Minivan
                </MenuItem>
                <MenuItem value={DriverVehicleInformationVehicleTypes.suv}>
                  SUV
                </MenuItem>
                <MenuItem value={DriverVehicleInformationVehicleTypes.sedan}>
                  Sedan
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={"License Plate #"}
              fullWidth
              value={licensePlateNumber}
              error={Boolean(isAnyKeyValueTrue(formErrors.licensePlateNumber))}
              helperText={isAnyKeyValueTrue(formErrors.licensePlateNumber)}
              onChange={(e) => {
                setLicensePlateNumber(e.target.value);
              }}
              onFocus={() => {
                setFormFieldToFocused("licensePlateNumber");
              }}
              onBlur={() => {
                setFormFieldToBlurred("licensePlateNumber");
              }}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <TextField
              label={"Make of Car"}
              fullWidth
              value={makeOfCar}
              error={Boolean(isAnyKeyValueTrue(formErrors.makeOfCar))}
              helperText={isAnyKeyValueTrue(formErrors.makeOfCar)}
              onChange={(e) => {
                setMakeOfCar(e.target.value);
              }}
              onFocus={() => {
                setFormFieldToFocused("makeOfCar");
              }}
              onBlur={() => {
                setFormFieldToBlurred("makeOfCar");
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={"Car Color"}
              fullWidth
              value={carColor}
              error={Boolean(isAnyKeyValueTrue(formErrors.carColor))}
              helperText={isAnyKeyValueTrue(formErrors.carColor)}
              onChange={(e) => {
                setCarColor(e.target.value);
              }}
              onFocus={() => {
                setFormFieldToFocused("carColor");
              }}
              onBlur={() => {
                setFormFieldToBlurred("carColor");
              }}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Year of Make</InputLabel>
              <Select
                value={carYear}
                label="Year of Make"
                onChange={(e) => {
                  setCarYear(e.target.value);
                }}
                onFocus={() => {
                  setFormFieldToFocused("carYear");
                }}
                onBlur={() => {
                  setFormFieldToBlurred("carYear");
                }}
              >
                {yearRange.map((year) => (
                  <MenuItem value={year}>{year}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item>
          <Typography fontWeight={600} fontSize={16} sx={{ color: "black" }}>
            Vehicle Photos
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ImageList cols={imageListSection?.length}>
            {imageListSection.map((item: any, index: any) => (
              <ImageListItem
                sx={{ height: 185 }}
                key={`vehicle-information-vehicle-photo-list-section-${index}`}
              >
                <img
                  srcSet={`${item.value}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  src={`${item.value}?w=164&h=164&fit=crop&auto=format`}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default DriverVehicleEditModal;

import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { GridSortModel } from "@mui/x-data-grid";
import qs from "qs";

const getCompanyUsers = async (
  accessToken: string,
  companyId: string,
  pageParam: number,
  search?: string,
  sort?: string
) => {
  try {
    if (accessToken) {
      const result = await axios.get(
        `${
          process.env.REACT_APP_BACKEND_URL
        }/admin/company/${companyId}/company-users?page=${pageParam}${
          search ? `&search=${search}` : ""
        }${sort ? `&${sort}` : ""}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      return result?.data;
    } else {
      return [];
    }
  } catch (error) {
    console.log("error", error);
  }
};

const addNewCompanyStaff = async ({
  accessToken,
  companyId,
  firstName,
  lastName,
  email,
  phoneNumber,
  companyAccountRole,
}: {
  accessToken: string;
  companyId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  companyAccountRole: string;
}) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/admin/company/${companyId}/invite-company-staff`,
      {
        firstName,
        lastName,
        email,
        phoneNumber,
        companyAccountRole,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    console.log("data", data);

    return data;
  } catch (error: any) {
    throw error?.response?.data || error;
  }
};

const modifyCompanyUserType = async ({
  userId,
  accessToken,
  accountType,
  companyId,
}: {
  userId: string;
  companyId: string;
  accessToken: string;
  accountType: string;
}) => {
  try {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/company/${companyId}/modify-company-user-type/${userId}`,
      { accountType },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

const removeCompanyUser = async ({
  userId,
  accessToken,
  companyId,
}: {
  userId: string;
  accessToken: string;
  companyId: string;
}) => {
  try {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}/admin/company/${companyId}/remove-company-user/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

const useCompanyUsers = ({
  accessToken,
  page = 0,
  companyId,
  search,
  sort,
}: {
  accessToken: string;
  companyId: string;
  page: number;
  search?: string;
  sort?: GridSortModel;
}) => {
  return useQuery({
    queryKey: [
      "company-users",
      companyId,
      page,
      ...(search ? [search] : []),
      ...(sort && sort?.length > 0 ? [sort[0]] : []),
    ],
    queryFn: () =>
      getCompanyUsers(
        accessToken,
        companyId,
        page,
        search,
        sort && sort?.length > 0 ? qs.stringify({ sortBy: sort[0] }) : undefined
      ),
    keepPreviousData: true,
  });
};

export {
  getCompanyUsers,
  useCompanyUsers,
  addNewCompanyStaff,
  removeCompanyUser,
  modifyCompanyUserType,
};

import { Box, Grid, Hidden, Typography } from "@mui/material";
import LeftLoginSection from "./LeftLoginSection/LeftLoginSection";
import LoginForm from "./LoginForm/LoginForm";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { userAuthGatewayLoadingState, userAuthState } from "../../atom";
import { useRecoilState } from "recoil";
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";
const Login = () => {
  const [authDetails, setAuthDetails] = useRecoilState(userAuthState);
  const [authGatewayLoading, setAuthGatewayLoading] = useRecoilState(
    userAuthGatewayLoadingState
  );
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || "/dashboard" || "/";

  useEffect(() => {
    if (authDetails?.accessToken) {
      console.log("from", from);
      navigate(from, { replace: true });
    }
  }, [authDetails, navigate, from]);

  return (
    <>
      <LoadingOverlay visible={authGatewayLoading} />
      <Grid container height="100vh">
        <LeftLoginSection />
        <LoginForm />
      </Grid>
    </>
  );
};

export default Login;

import { Grid, Typography, Box } from "@mui/material";
import { useState } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridPaginationModel,
  GridSortModel,
} from "@mui/x-data-grid";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { darken, lighten, styled } from "@mui/material/styles";

import {
  useDriverEarnings,
  useDriverServiceCallHistory,
} from "../../../hooks/useDrivers";
import PaginationTable from "../../../components/PaginationTable/PaginationTable";
import moment from "moment";
import {
  renderDriverPrice,
  roundToPrecision,
} from "../../../helpers/formatters";
import {
  PickUpStatus,
  cancelledStatuses,
  inProgressStatuses,
  pendingStatuses,
} from "../../../helpers/validationHelpers";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { Link } from "react-router-dom";

const EarningsTab = ({
  accessToken,
  userId,
}: {
  accessToken?: string;
  userId?: string;
}) => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "createdAt",
      sort: "desc",
    },
  ]);

  const {
    data: serviceCallHistoryData,
    isLoading: isServiceCallHistoryLoading,
    isFetching: isServiceCallHistoryFetching,
  } = useDriverServiceCallHistory({
    accessToken,
    userId,
    page: paginationModel.page,
    sort: sortModel,
  });

  const {
    data: driverEarningsData,
    isLoading: isEarningsLoading,
    isFetching: isEarningsFetching,
  } = useDriverEarnings({ accessToken, userId });

  const renderStatus = (pickUpStatus: PickUpStatus) => {
    if (cancelledStatuses.includes(pickUpStatus)) {
      return (
        <Typography sx={{ color: "#FD5554" }}>
          {pickUpStatus === PickUpStatus.scheduledDeclined
            ? "Declined"
            : "Cancelled"}
        </Typography>
      );
    } else if (pendingStatuses.includes(pickUpStatus)) {
      return <Typography sx={{ color: "#1162FB" }}>Pending</Typography>;
    } else if (inProgressStatuses.includes(pickUpStatus)) {
      return <Typography sx={{ color: "#1162FB" }}>In Progress</Typography>;
    } else {
      return <Typography sx={{ color: "#3fbf5d" }}>Completed</Typography>;
    }
  };

  const handleSortModelChange = async (model: GridSortModel) => {
    setSortModel(model);
  };

  const currentIsoWeek = moment().isoWeek();

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Service Call ID",
      width: 150,
      renderCell: (params) => (
        <Link
          to={
            params?.row?.scheduledPickUpRequest
              ? `/dashboard/scheduled-service-calls/${params.row.id}`
              : `/dashboard/service-calls/${params.row.id}`
          }
        >
          {params.row.id}
        </Link>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 225,
      renderCell: (params) => (
        <Typography>
          {moment(params.row.createdAt).format("Do MMMM  YYYY, h:mm a")}
        </Typography>
      ),
    },
    {
      field: "totalFare",
      headerName: "Total Fare",
      valueGetter: (params) =>
        roundToPrecision(
          (params?.row?.price +
            params?.row?.taxAmountInCents +
            params?.row?.tipAmountInCents) /
            100,
          2
        ),
    },
    {
      field: "amountEarned",
      headerName: "Earning",
      valueGetter: (params) =>
        renderDriverPrice(
          params?.row?.price + params?.row?.taxAmountInCents,
          params?.row?.tipAmountInCents
        ),
    },
    {
      field: "pickUpStatus",
      headerName: "Status",
      valueGetter: (params) => params.row.pickUpStatus,
      renderCell: (params) => renderStatus(params.row.pickUpStatus),
    },
  ];

  return (
    <Grid
      sx={{ padding: 2, position: "relative" }}
      container
      direction={"column"}
    >
      <Grid
        container
        item
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Grid
          container
          item
          xs={3.75}
          sx={{ backgroundColor: "#fdecd3", py: 2, px: 2, borderRadius: 1 }}
          justifyContent={"space-between"}
          alignContent={"center"}
          alignItems={"center"}
        >
          <Grid item xs={8}>
            <Typography sx={{ color: "#ceb791" }} pb={2}>
              Current pay period
            </Typography>
            <Typography fontWeight={600} fontSize={16}>
              $
              {driverEarningsData?.earnings[0]
                ? renderDriverPrice(
                    driverEarningsData?.earnings[0]?.price,
                    driverEarningsData?.earnings[0]?.tip
                  )
                : 0}
            </Typography>
          </Grid>
          <Grid item>
            <MonetizationOnIcon
              sx={{ color: "#fefaf1", width: 40, height: 40 }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={3.75}
          sx={{ backgroundColor: "#ffe3fb", py: 2, px: 2, borderRadius: 1 }}
          justifyContent={"space-between"}
          alignContent={"center"}
          alignItems={"center"}
        >
          <Grid item xs={8}>
            <Typography sx={{ color: "#dca1d4" }} pb={2}>
              Last pay period
            </Typography>
            <Typography fontWeight={600} fontSize={16}>
              $
              {driverEarningsData?.earnings[1]
                ? renderDriverPrice(
                    driverEarningsData?.earnings[1]?.price,
                    driverEarningsData?.earnings[1]?.tip
                  )
                : 0}
            </Typography>
          </Grid>
          <Grid item>
            <MonetizationOnIcon
              sx={{ color: "#fef7ff", width: 40, height: 40 }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={3.75}
          sx={{ backgroundColor: "#b4e0e9", py: 2, px: 2, borderRadius: 1 }}
          justifyContent={"space-between"}
          alignContent={"center"}
          alignItems={"center"}
        >
          <Grid item xs={8}>
            <Typography sx={{ color: "#729da7" }} pb={2}>
              Next pay period
            </Typography>
            <Typography fontWeight={600} fontSize={16}>
              {moment()
                .isoWeek(currentIsoWeek)
                .add(1, "week")
                .isoWeekday(1)
                .format("ddd, MMM Do")}
            </Typography>
          </Grid>
          <Grid item>
            <CalendarTodayOutlinedIcon
              sx={{ color: "#d2ecf1", width: 30, height: 30 }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item py={2}>
        <Typography fontWeight={600} fontSize={16}>
          Earnings
        </Typography>
      </Grid>
      <Grid container item>
        <PaginationTable
          columns={columns}
          loading={isServiceCallHistoryLoading || isServiceCallHistoryFetching}
          rows={serviceCallHistoryData?.data ?? []}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={(model: any) => {
            setPaginationModel(model);
          }}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={(model: any) => {
            handleSortModelChange(model);
          }}
          totalCount={serviceCallHistoryData?.totalCount}
          pageCount={serviceCallHistoryData?.totalPages}
        />
      </Grid>
    </Grid>
  );
};

export default EarningsTab;

import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Rating,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import DashboardLayout from "../../../layout/DashboardLayout/DashboardLayout";
import BreadcrumbSection from "../../../components/BreadcrumbSection/BreadcrumbSection";
import { useDriverDetails } from "../../../hooks/useDrivers";
import { getAccessToken } from "../../../helpers/authHelpers";

import ProfilePanel from "../../../components/ProfilePanel/ProfilePanel";
import ProfileListItem from "../../../components/ProfilePanel/ProfileListItem";

import { useEffect, useRef, useState } from "react";
import { useServiceCallDetails } from "../../../hooks/useServiceCalls";
import moment from "moment";
import {
  calculateElapsedTime,
  roundToPrecision,
} from "../../../helpers/formatters";
import {
  PickUpStatus,
  cancelledStatuses,
  customerCancelledStatuses,
  driverCancelStatuses,
  formatVehicleTypesForServiceCall,
  getVehicleTypesForServiceCall,
  mappedStatuses,
  mappedVehicleTypes,
  toTitleCase,
} from "../../../helpers/validationHelpers";
import MapSection from "../../../components/GoogleMaps/GoogleMaps";
import GoogleMaps from "../../../components/GoogleMaps/GoogleMaps";
import {
  DirectionsRenderer,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import ImageModal from "../../../components/ImageModal/ImageModal";
import useElementSizeObserver from "../../../hooks/useElementSizeObserver";
import { useServiceCallSubmissionAttemptDetails } from "../../../hooks/useServiceCallSubmissionAttempts";
import { AuthContext } from "../../../context/authContext";
import { useRecoilState } from "recoil";
import { userAuthState } from "../../../atom";

const ServiceCallSubmissionAttemptDetails = () => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
  });

  const { serviceCallSubmissionAttemptId } = useParams();

  const [authDetails, setAuthDetails] = useRecoilState(userAuthState);

  const { data: serviceCallSubmissionAttemptData, isLoading } =
    useServiceCallSubmissionAttemptDetails(
      authDetails?.accessToken,
      serviceCallSubmissionAttemptId
    );

  const [directionsResponse, setDirectionsResponse] = useState<any>();
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [imageToLoad, setImageToLoad] = useState({ label: "", value: "" });

  const {
    width: customerCargoUploadsContainerWidth,
    height: customerCargoUploadsContainerHeight,
    elementRef: customerCargoUploadsRef,
  } = useElementSizeObserver();

  console.log("driverData", serviceCallSubmissionAttemptData);
  const styles = {
    listLabel: {
      color: "#626262",
    },
    listValue: {
      color: "#242424",
    },
    tab: {
      textTransform: "capitalize",
    },
  };

  const renderVehicleType = () => {
    const vehicleTypes = getVehicleTypesForServiceCall(
      serviceCallSubmissionAttemptData?.formFields?.requestedVehicleClass,
      serviceCallSubmissionAttemptData?.formFields?.requestedVehicleType
    );

    return vehicleTypes?.length > 0
      ? formatVehicleTypesForServiceCall(vehicleTypes)
      : "N/A";
  };

  const customerPrimarySectionFields = [
    {
      label: "Email Address",
      value: serviceCallSubmissionAttemptData?.customer?.email,
    },
    {
      label: "Phone No.",
      value: serviceCallSubmissionAttemptData?.customer?.phoneNumber,
    },
    {
      label: "Created At",
      value: moment(
        serviceCallSubmissionAttemptData?.customer?.createdAt
      ).format("Do MMMM  YYYY, h:mm:ss a"),
    },
    {
      label: "Updated At",
      value: moment(
        serviceCallSubmissionAttemptData?.customer?.updatedAt
      ).format("Do MMMM  YYYY, h:mm:ss a"),
    },
  ];

  const serviceCallPrimaryListSection = [
    {
      label: "Pick Up Address",
      value: serviceCallSubmissionAttemptData?.formFields?.originAddress,
    },
    {
      label: "Service Call Type",
      value:
        serviceCallSubmissionAttemptData?.pickUpRequestSubmissionType ===
        "ONDEMAND"
          ? "On-Demand"
          : "Scheduled",
    },
    {
      label: "Created At",
      value: moment(serviceCallSubmissionAttemptData?.createdAt).format(
        "Do MMMM  YYYY, h:mm:ss a"
      ),
    },
  ];

  const serviceCallSecondaryListSection = [
    {
      label: "Destination Address",
      value: serviceCallSubmissionAttemptData?.formFields?.destinationAddress,
    },
    {
      label: "Vehicle Types",
      value: renderVehicleType(),
    },
    {
      label: "Driver Type",
      value: serviceCallSubmissionAttemptData?.formFields
        ?.requestedServiceDriverType
        ? toTitleCase(
            serviceCallSubmissionAttemptData?.formFields
              ?.requestedServiceDriverType
          )
        : "N/A",
    },
  ];

  const paymentSection = [
    {
      label: "Name on Card",
      value:
        serviceCallSubmissionAttemptData?.customer?.defaultPaymentMethod
          ?.nameOnCard ?? "N/A",
    },
    {
      label: "Brand",
      value: serviceCallSubmissionAttemptData?.customer?.defaultPaymentMethod
        ? `${toTitleCase(
            serviceCallSubmissionAttemptData?.customer?.defaultPaymentMethod
              ?.brand
          )} ending in ${
            serviceCallSubmissionAttemptData?.customer?.defaultPaymentMethod
              ?.lastFour
          }`
        : "N/A",
    },
    {
      label: "Expiry date",
      value: serviceCallSubmissionAttemptData?.customer?.defaultPaymentMethod
        ? `${serviceCallSubmissionAttemptData?.customer?.defaultPaymentMethod?.expiryMonth}/${serviceCallSubmissionAttemptData?.customer?.defaultPaymentMethod?.expiryYear}`
        : "N/A",
    },
  ];

  const customerCargoImageListSection = [
    {
      label: "Customer Cargo Image",
      value:
        serviceCallSubmissionAttemptData?.formFields?.customerCargoUploadOne,
    },
    {
      label: "Customer Cargo Image",
      value:
        serviceCallSubmissionAttemptData?.formFields?.customerCargoUploadTwo,
    },
    {
      label: "Customer Cargo Image",
      value:
        serviceCallSubmissionAttemptData?.formFields?.customerCargoUploadThree,
    },
  ].filter((image) => image.value);

  useEffect(() => {
    const getRoute = async () => {
      const directionsService = new window.google.maps.DirectionsService();
      const results = await directionsService.route({
        origin: {
          lat: Number(
            serviceCallSubmissionAttemptData?.formFields?.originLatitude
          ),
          lng: Number(
            serviceCallSubmissionAttemptData?.formFields?.originLongitude
          ),
        },
        destination: {
          lat: Number(
            serviceCallSubmissionAttemptData?.formFields?.destinationLatitude
          ),
          lng: Number(
            serviceCallSubmissionAttemptData?.formFields?.destinationLongitude
          ),
        },
        travelMode: window.google.maps.TravelMode.DRIVING,
      });
      setDirectionsResponse(results);
    };

    if (serviceCallSubmissionAttemptData?.formFields && isLoaded) {
      getRoute();
    }
  }, [serviceCallSubmissionAttemptData?.formFields, isLoaded]);

  return (
    <DashboardLayout loading={isLoading}>
      <BreadcrumbSection
        crumbs={[
          {
            url: "/dashboard",
            title: "Dashboard",
          },
          {
            url: "/dashboard/service-call-submission-attempts",
            title: "Service Calls",
          },
          {
            url: `/dashboard/service-call-submission-attempts/${serviceCallSubmissionAttemptId}`,
            title: `${serviceCallSubmissionAttemptId}`,
          },
        ]}
        pageTitle={`Service Calls`}
        withBackNav
        backNavLabel={"Return to Listing"}
        backNavUrl="/dashboard/service-call-submission-attempts"
      />
      <Grid container mt={1} spacing={3} pb={4}>
        {/* left panel */}

        <ProfilePanel
          accessToken={authDetails?.accessToken}
          firstName={serviceCallSubmissionAttemptData?.customer?.firstName}
          lastName={serviceCallSubmissionAttemptData?.customer?.lastName}
          avatar={serviceCallSubmissionAttemptData?.customer?.avatar}
          userId={serviceCallSubmissionAttemptData?.customer?.id}
          rating={serviceCallSubmissionAttemptData?.customer?.rating}
          isOnline={false}
          withEdit={false}
          onEditClick={() => {}}
        >
          <Grid container spacing={2} direction={"column"}>
            {customerPrimarySectionFields.map((field, index) => (
              <ProfileListItem
                key={`service-call-submission-attempts-profile-list-item-${index}`}
                label={field.label}
                value={field.value}
              />
            ))}
          </Grid>
        </ProfilePanel>

        {/* right panel */}
        <Grid item xs={12} lg={8}>
          <Grid item xs={12} pb={3}>
            <Paper sx={{ padding: 2 }}>
              <Grid
                container
                spacing={2}
                direction={"column"}
                justifyContent={"space-evenly"}
              >
                <Grid item>
                  <Typography fontWeight={600} fontSize={16}>
                    Service Call Details
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  direction="row"
                  paddingBottom={2}
                  justifyContent={"space-between"}
                  spacing={10}
                >
                  <Grid item xs={12} md={6}>
                    <Grid container direction={"column"} spacing={1}>
                      {serviceCallPrimaryListSection.map(
                        ({ label, value }, index) => (
                          <ProfileListItem
                            key={`service-call-submission-attempts-primary-list-section-${index}`}
                            label={label}
                            value={value}
                          />
                        )
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container direction={"column"} spacing={1}>
                      {serviceCallSecondaryListSection.map(
                        ({ label, value }, index) => (
                          <ProfileListItem
                            key={`service-call-submission-attempts-secondary-list-section-${index}`}
                            label={label}
                            value={value}
                          />
                        )
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* user avatars, map, and customer payment details */}
          <Grid item xs={12} pb={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <Paper sx={{ height: "100%" }}>
                  <GoogleMaps>
                    {serviceCallSubmissionAttemptData?.formFields
                      ?.originLatitude &&
                      serviceCallSubmissionAttemptData?.formFields
                        ?.originLongitude && (
                        <Marker
                          title="Pickup"
                          position={{
                            lat: Number(
                              serviceCallSubmissionAttemptData?.formFields
                                .originLatitude
                            ),
                            lng: Number(
                              serviceCallSubmissionAttemptData?.formFields
                                .originLongitude
                            ),
                          }}
                        />
                      )}
                    {serviceCallSubmissionAttemptData?.formFields
                      ?.destinationLatitude &&
                      serviceCallSubmissionAttemptData?.formFields
                        ?.destinationLongitude && (
                        <Marker
                          title="Dropoff"
                          position={{
                            lat: Number(
                              serviceCallSubmissionAttemptData?.formFields
                                .destinationLatitude
                            ),
                            lng: Number(
                              serviceCallSubmissionAttemptData?.formFields
                                .destinationLongitude
                            ),
                          }}
                        />
                      )}
                    {directionsResponse && (
                      <DirectionsRenderer directions={directionsResponse} />
                    )}
                  </GoogleMaps>
                </Paper>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Paper sx={{ height: "100%" }}>
                  <Grid item py={1} px={2}>
                    <Typography fontWeight={600} fontSize={16}>
                      Payment Info
                    </Typography>
                  </Grid>
                  <Divider />
                  <Grid item py={2} px={2}>
                    <Grid container direction={"column"} spacing={1}>
                      {paymentSection.map(({ label, value }, index) => (
                        <ProfileListItem
                          key={`service-call-submission-attempts-payment-methods-primary-list-section-${index}`}
                          label={label}
                          value={value}
                        />
                      ))}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <ImageModal
            open={imageModalOpen}
            title={imageToLoad.label}
            imageURL={imageToLoad.value}
            handleClose={() => {
              setImageToLoad({ label: "", value: "" });
              setImageModalOpen(false);
            }}
          />
          {/* activities and customer cargo image */}
          <Grid item xs={12} pb={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <Paper sx={{ height: "100%" }}>
                  <Grid item py={1} px={2}>
                    <Typography fontWeight={600} fontSize={16}>
                      Failure Reason
                    </Typography>
                  </Grid>

                  <Divider />

                  <Grid
                    container
                    direction={"column"}
                    sx={{
                      maxHeight: customerCargoUploadsContainerHeight,
                      position: "relative",
                      overflow: "auto",
                    }}
                  >
                    <List disablePadding>
                      <ListItem>
                        <ListItemText
                          primary={
                            serviceCallSubmissionAttemptData?.failureReason
                          }
                        />
                      </ListItem>
                    </List>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Paper sx={{ height: "100%" }}>
                  <Grid item py={1} px={2}>
                    <Typography fontWeight={600} fontSize={16}>
                      Picture of cargo taken by customer
                    </Typography>
                  </Grid>
                  <Divider />
                  <Grid
                    container
                    item
                    justifyContent={"flex-start"}
                    alignContent={"center"}
                    px={2}
                    ref={customerCargoUploadsRef}
                  >
                    {customerCargoImageListSection.length > 0 ? (
                      <Grid item xs={12}>
                        <ImageList cols={4}>
                          {customerCargoImageListSection.map((item, index) => (
                            <ImageListItem
                              onClick={() => {
                                setImageToLoad(item);
                                setImageModalOpen(true);
                              }}
                              sx={{ height: 185, cursor: "pointer" }}
                              key={`customer-cargo-photo-list-section-${index}`}
                            >
                              <img
                                srcSet={`${item.value}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                src={`${item.value}?w=164&h=164&fit=crop&auto=format`}
                                loading="lazy"
                                aria-label={item.label}
                              />
                            </ImageListItem>
                          ))}
                        </ImageList>
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        <Grid
                          container
                          justifyContent={"center"}
                          alignContent={"center"}
                          alignItems={"center"}
                        >
                          <Grid item p={7}>
                            <Typography sx={{ color: "#626262" }}>
                              No image found
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default ServiceCallSubmissionAttemptDetails;

import {
    Avatar,
    Backdrop,
    Box,
    CircularProgress,
    Typography,
  } from "@mui/material";
  import { ReactComponent as AcronymLogo } from "../../assets/images/acronym-logo.svg";
  
  const LoadingOverlay = ({ visible }: { visible: boolean }) => {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={visible}
      >
        <Box position="relative" display="inline-flex">
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <AcronymLogo width={70} height={70} />
          </Box>
          <CircularProgress size={120} sx={{ color: "rgb(242, 104, 34)" }} />
        </Box>
      </Backdrop>
    );
  };
  
  export default LoadingOverlay;
  